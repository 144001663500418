@media all and (min-width: 1000px) {
    .hide-desktop { display:none; };
    .hide-mobile { display:block; };
}
@media all and (max-width: 1000px) {
    .hide-desktop { display:block; };
    .hide-mobile { display:none; };
}

.top-menu-link {
    position: relative;
    top: 17px;
    /* font-family: maturin; */
    color: white;
}

.top-menu-link a {
    padding-bottom: 5px;
    position: relative;
    top: 5px;
}

.top-menu-link :hover {
    color: white!important;
}

.font-maturin {
    font-family: maturin;
}

/*Underline effect*/
.underline-effect, .underline-effect-l2, .underline-effect-l3 {
    position: relative;
    transition: color 0.1s, background-color 0.1s, padding 0.2s ease-in;
    -webkit-transition: color 0.1s, background-color 0.1s, padding 0.2s ease-in;
    -o-transition: color 0.1s, background-color 0.1s, padding 0.2s ease-in;
    -moz-transition: color 0.1s, background-color 0.1s, padding 0.2s ease-in;
}

.underline-effect::before, .underline-effect-l2::before, .underline-effect-l3::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    transform-origin: left top;
    transform: scale(0, 1);
    transition: color 0.1s, transform 0.2s ease-out;
    -webkit-transition: color 0.1s, transform 0.2s ease-out;
    -o-transition: color 0.1s, transform 0.2s ease-out;
    -moz-transition: color 0.1s, transform 0.2s ease-out;
}

.underline-effect-orange::before {
    background-color: #f0632f;
}

.underline-effect-black::before {
    background-color: black;
}

.underline-effect-white::before {
    background-color: white;
}

.underline-effect-gray::before {
    background-color: #999;
}

.underline-effect-h1::before {
    height: 1px;
}

.underline-effect-h2::before {
    height: 2px;
}

.underline-effect.underline-effect-relative {
}

.underline-effect-wrapper:hover .underline-effect::before, .underline-effect-wrapper:focus .underline-effect::before,
.underline-effect-wrapper:active .underline-effect::before,
.underline-effect-wrapper-l2:hover .underline-effect-l2::before, .underline-effect-wrapper-l2:focus .underline-effect-l2::before,
.underline-effect-wrapper-l2:active .underline-effect-l2::before,
.underline-effect-wrapper-l3:hover .underline-effect-l3::before, .underline-effect-wrapper-l3:focus .underline-effect-l3::before,
.underline-effect-wrapper-l3:active .underline-effect-l3::before {
    transform-origin: left top;
    transform: scale(1, 1);
}

.ui-autocomplete-category {
    font-weight: bold;
    padding: .2em .4em;
    margin: .8em 0 .2em;
    line-height: 1.5;
}

.ui-autocomplete .ui-menu-item { padding-left: 10px; }

.notification-listing {
    max-height: 50vh;
    overflow-y: scroll;
}

.favorites-listing {
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.favorites-dropdown {
    width: fit-content;
}

/*
To move elsewhere this is Cart Page stuff (Not yet implemented)
@TODO
 */
    .product-description .short_desc {
        float: left;
        width: fit-content;
        padding-left: 15px;
        min-height: 150px;
    }

    .table-cart th {
        padding-bottom: 0px!important;
        padding-top: 0px!important;
    }

    .cart-quantity-td {
        padding-left: 0px!important;
        padding-right: 0px!important;
        margin-right: 0px!important;
        margin-left: 0px!important;
    }

    .cart-product-name { margin: 0px!important; }
    .cart-multiple-pricing {
        color: #f2835f!important;
        text-decoration: underline!important;
        cursor: pointer!important;
    }

    .table-cart .p_image img { width: 100%!important; }

    .cart-quantity-reduction {
        color: #f2835f!important;
    }

    .no-js #loader { display: none;  }
    .js #loader { display: block; position: absolute; left: 100px; top: 0; }
    .se-pre-con {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background: url(Frontend/images/loading.gif) center no-repeat #fff;
    }

    @media only screen and (max-width: 600px) {
      .mobile_product_row {
        margin-right: 0px;
        margin-left: 15px;
      }

      .mobile_product_box {
        width: 45%;
        padding: 0px;
        margin: 5px;
      }
      .d-none-btn-add-to-cart {
        display: block !important;
      }
      .products-wrap .product {
        padding-left:0px;
      }
    }
